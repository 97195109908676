import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

import generateAuthRoutes from './sub-modules/auth/routes'
import generateOnboardedRoutes from './sub-modules/onboarded/routes'
import generateOnboardingRoutes from './sub-modules/onboarding/routes'

// TODO: Add bo_merchant to the following routes
const badoomRoutesNotNamespaced = [
  {
    name: ROUTES.ADMIN.HOME,
    path: '/admin',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.PAYPAL_CONNECT_ACCOUNT,
    path: '/paypal/merchant_onboard',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.PASSWORD_RESET,
    path: '/password_reset/?m=merchant',
    meta: { disableClientSideNavigation: true },
  },
  // Merchant product preview URL
  {
    name: ROUTES.PRODUCT.PREVIEW,
    path: '/preview/:id',
    meta: { disableClientSideNavigation: true },
  },
]

const badoomRoutes = [
  {
    name: ROUTES.BO_MERCHANT.ROOT,
    path: '',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.DASHBOARD,
    path: 'dashboard',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.SOURCING.DASHBOARD,
    path: 'sourcing/dashboard',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.SOURCING.ORDER,
    path: 'sourcing/orders/:orderId',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.SOURCING.LISTINGS,
    path: 'sourcing/listings',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.SOURCING.INVOICES,
    path: 'sourcing/invoices',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.SOURCING.OPTIONS,
    path: 'sourcing/options',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.PERFORMANCES,
    path: 'performances',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.LISTINGS.CREATE.PRODUCT.CREATE,
    path: 'listings/new/product',
    meta: { disableClientSideNavigation: true },
  },

  {
    name: ROUTES.BO_MERCHANT.DOCUSIGN_CGU,
    path: 'merchant/cgu/sign',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.QUALITY_CHARTER,
    path: 'merchant_support/charter',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.OLD_MERCHANT_ORDERS,
    path: 'order-old',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.CLIENTS,
    path: 'admin_tools/clients',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.LOGOUT,
    path: 'logout',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_MERCHANT.PAYOUT_INFORMATION,
    path: 'kyb/adyens_onboarding_page',
    meta: { disableClientSideNavigation: true },
  },
]

const boSellerRoutes = [
  {
    name: ROUTES.BO_SELLER.LOGIN,
    path: '/bo-seller/login',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.REGISTER,
    path: '/bo-seller/register',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.HOME,
    path: '/bo-seller/',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.INSIGHTS,
    path: '/bo-seller/insights',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.INVOICES.WALLET,
    path: '/bo-seller/invoices/wallet',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.INVOICES.PAST_INVOICES,
    path: '/bo-seller/invoices/past-invoices',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.INVOICES.GOODWILL_GESTURES,
    path: '/bo-seller/invoices/goodwill-gestures',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.INVOICES.SELLER_COMPENSATION,
    path: '/bo-seller/invoices/seller-compensation',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.CUSTOMER_CARE.INDEX,
    path: '/bo-seller/customer-care',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.CUSTOMER_CARE.OPEN_TASKS,
    path: '/bo-seller/customer-care/open-tasks',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.CUSTOMER_CARE.ALL_REQUESTS,
    path: '/bo-seller/customer-care/all-requests',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.CUSTOMER_REVIEWS,
    path: '/bo-seller/customer-reviews',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.MARKETS,
    path: '/bo-seller/options/markets',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.ADDRESSES,
    path: '/bo-seller/options/addresses',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.SHIPPING,
    path: '/bo-seller/options/shipping',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.PAYMENT,
    path: '/bo-seller/options/payment',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.HYPERWALLET.KYB,
    path: '/bo-seller/options/payment/hyperwallet/kyb',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.HYPERWALLET.TRANSFER_METHODS,
    path: '/bo-seller/options/payment/hyperwallet/transfer-methods',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.HYPERWALLET.CONFIRMATION,
    path: '/bo-seller/options/payment/hyperwallet/confirmation',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.PRICING,
    path: '/bo-seller/options/pricing',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.BATCH_LISTING_CREATION,
    path: '/bo-seller/options/batch-listing-creation',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.BACKPRICER,
    path: '/bo-seller/options/backpricer',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPTIONS.INTEGRATIONS,
    path: '/bo-seller/options/integrations',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ORDERS.INDEX,
    path: '/bo-seller/orders',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.PROFILE.HOME,
    path: '/bo-seller/profile/home',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.PROFILE.COMPANY_INFO,
    path: '/bo-seller/profile/company-info',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.PROFILE.LEGAL_DETAILS,
    path: '/bo-seller/profile/legal-details',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.PROFILE.CONTACT_DETAILS,
    path: '/bo-seller/profile/contact-details',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.PROFILE.USER_PERMISSIONS,
    path: '/bo-seller/profile/user-permissions',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.HOME,
    path: '/bo-seller/onboarding',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.LISTINGS.ACTIVE,
    path: '/bo-seller/onboarding/listings/active',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.LISTINGS.MISSING_INFOS,
    path: '/bo-seller/onboarding/listings/missing-infos',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.LISTINGS.IN_VALIDATION,
    path: '/bo-seller/onboarding/listings/in-validation',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.LISTINGS.ARCHIVED,
    path: '/bo-seller/onboarding/listings/archived',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.LISTINGS.CREATE.PRODUCT.SEARCH,
    path: '/bo-seller/onboarding/listings/create',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.LISTINGS.CREATE.DETAILS,
    path: '/bo-seller/onboarding/listings/create/:productId',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.LISTINGS.CREATE.PRODUCT.DUPLICATE,
    path: '/bo-seller/onboarding/duplicate_product/:productBmId',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.PROFILE.HOME,
    path: '/bo-seller/onboarding/profile/home',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.PROFILE.COMPANY_INFO,
    path: '/bo-seller/onboarding/profile/company-info',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.PROFILE.LEGAL_DETAILS,
    path: '/bo-seller/onboarding/profile/legal-details',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.PROFILE.CONTACT_DETAILS,
    path: '/bo-seller/onboarding/profile/contact-details',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.PROFILE.USER_PERMISSIONS,
    path: '/bo-seller/onboarding/profile/user-permissions',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.OPTIONS.MARKETS,
    path: '/bo-seller/onboarding/options/markets',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.OPTIONS.ADDRESSES,
    path: '/bo-seller/onboarding/options/addresses',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.OPTIONS.SHIPPING,
    path: '/bo-seller/onboarding/options/shipping',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.OPTIONS.PAYMENT,
    path: '/bo-seller/onboarding/options/payment',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.OPTIONS.PRICING,
    path: '/bo-seller/onboarding/options/pricing',
    meta: { disableClientSideNavigation: true },
  },
  // {
  //   name: ROUTES.BO_SELLER.ONBOARDING.OPTIONS.BATCH_LISTING_CREATION,
  //   path: '/bo-seller/onboarding/options/batch-listing-creation',
  //   meta: { disableClientSideNavigation: true },
  // },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.OPTIONS.BACKPRICER,
    path: '/bo-seller/onboarding/options/backpricer',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.ONBOARDING.OPTIONS.INTEGRATIONS,
    path: '/bo-seller/onboarding/options/integrations',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.OPPORTUNITIES.PRICING,
    path: '/bo-seller/opportunities/pricing',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.LISTINGS.PRODUCT.SEARCH,
    path: '/bo-seller/listings/product/search',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.CREATE_SHIPPING_LABEL,
    path: '/bo-seller/shipping/create-label',
    meta: { disableClientSideNavigation: true },
  },
]

const boBuybackRoutes = [
  {
    name: ROUTES.BO_SELLER.BUYBACK.WISHLIST,
    path: '/bo-seller/buyback/wishlist',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.BUYBACK.SEARCH_PRODUCT,
    path: '/bo-seller/buyback/search-product',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.BUYBACK.CREATE_QUOTATION,
    path: '/bo-seller/buyback/create-quotation/:productId',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.BUYBACK.ORDERS.ACTIVE,
    path: '/bo-seller/buyback/orders/active',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.BUYBACK.ORDERS.ALL,
    path: '/bo-seller/buyback/orders/all',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.BUYBACK.ORDERS.PENDING,
    path: '/bo-seller/buyback/orders/pending',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.BUYBACK.ORDERS.DETAILS,
    path: '/bo-seller/buyback/orders/:id',
    meta: { disableClientSideNavigation: true },
  },
  {
    name: ROUTES.BO_SELLER.BUYBACK.ORDERS.CONVERSATION,
    path: '/bo-seller/buyback/orders/:id/conversation',
    meta: { disableClientSideNavigation: true },
  },
]

export default (routerOptions) => {
  const authRoutes = generateAuthRoutes(routerOptions)
  const onboardedRoutes = generateOnboardedRoutes(routerOptions)
  const onboardingRoutes = generateOnboardingRoutes(routerOptions)

  return [
    {
      path: '/bo_merchant',
      component: () => dynamicImport(import('./layouts/Authenticated.vue')),
      meta: {
        auth: {
          required: true,
          redirection: { name: ROUTES.BO_MERCHANT.LOGIN },
        },
        seller: {
          required: true,
          redirection: { name: ROUTES.BO_MERCHANT.LOGIN },
        },
      },
      children: [
        ...authRoutes.requiresSellerAuthentication,
        ...onboardedRoutes.requiresSellerAuthentication,
        ...onboardingRoutes.requiresSellerAuthentication,
        ...badoomRoutes,
      ],
    },
    {
      path: '/bo_merchant',
      component: () => dynamicImport(import('./layouts/Unauthenticated.vue')),
      children: [
        ...authRoutes.other,
        ...onboardedRoutes.other,
        ...onboardingRoutes.other,
      ],
    },
    ...badoomRoutesNotNamespaced,
    ...boSellerRoutes,
    ...boBuybackRoutes,
  ]
}
